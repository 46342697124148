<template>
    <v-container class="ma-0 pa-0 d-flex flex-column align-center">
        <div class="mapColorBar">
            <div class="setBar">
                <div :style="`width:${1}px;position:relative;height:calc(100% - 60px);`">                
                    <div :style="`right:10px;position:absolute;white-space:nowrap;top:calc(${i.r * 100 }% - 10px);width:0px`" class="d-flex justify-end" v-for="i,k in valueInfo" :key="k+'s'">                                           
                        {{i.value}}
                    </div>                                                                        
                </div>
                <div :style="`width:${clientWidth}px;height:calc(100% - 60px);position:relative;`"  class="flex-column-reverse d-flex ">
                    <div v-show="tick" :style="`bottom:0;right:0px;position: relative;height:${i.h * 100}%;display:flex;flex-flow:column;justify-content:space-between;`" v-for="i,k in valueInfo" :key="k+'z'">
                        <div v-for="index in 5" :key="index+'z'" :style="`height:1px;width:${index % 5 == 0  || index == 0 ? 8:4 }px;background-color: rgba(0,0,0,.5);`"></div>
                    </div>     
                </div>

                <div :style="`width:${clientWidth}px;height:calc(100% - 60px);flex-shrink:0;`" class="mapColorBarBar flex-column-reverse d-flex flex-column flex-nowrap justify-center">                    
                    <div v-for="i,k in colorsBg" :key="k" :style="`background:${i};flex: auto;`">
                    </div>
                </div>      
                <div style="position:absolute;top: 0px;white-space: nowrap;" v-html="unit"></div>                                  
            </div>            
        </div>
        <v-menu offset-x nudge-left="120" nudge-top="280" bottom v-model="openMenu" 
        >
            <template v-slot:activator="{ on,attrs }">
                <v-btn 
                text
                fab 
                small
                v-bind="attrs"
                v-on="on"  
                :disabled="disabled"
                >
                <v-icon  :style="`z-index:9999;width:100%;height:100%;-webkit-background-clip: text;background-image:linear-gradient(to bottom right,${currentColorKey.colorList.join(',')});`" color="transparent">mdi-palette</v-icon>  
                </v-btn>                  
            </template>
            <!-- 色表卡设置 -->
            <v-card color="pa-2" :min-width="minWidth" max-height="500px" style="overflow: auto;">
                <v-row class="ma-0" @click.stop="">
                    <v-data-table                     
                    style="width:100%"
                    height="100%"                    
                    fixed-header 
                    :headers="headers" 
                    :items='colorBarParams'
                    disable-pagination
                    hide-default-footer
                    >
                    <!-- 添加新列 -->
                    <template v-slot:top> 
                        <v-row class="ma-0 justify-end"> 
                            <v-select v-model="currentColorMode" class="pt-1" label="色彩模式" dense hide-details hide-spin-buttons :items="[{text:'简单',value:0},{text:'渐变',value:1}]">
                                
                            </v-select>
                            <v-btn
                            text
                            color="secondary"                        
                            class="mb-2"
                            @click.stop="newParams"
                            >{{$i18n.t('components.colorBar.btn.new')}}
                            </v-btn> 
                            <v-btn
                            text
                            color="accent"
                            class="mb-2 mx-1"
                            @click.stop="reset">
                                {{$i18n.t('components.colorBar.btn.reset')}}
                            </v-btn>
                             <v-btn
                             color="primary"
                            text                            
                            class="mb-2 mx-1"
                            @click.stop="save">
                                {{$i18n.t('components.colorBar.btn.save')}}
                            </v-btn>
                            <v-btn
                             color="primary"
                             text
                             icon                                                     
                             class=""
                            @click.stop="openMenu=false">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-row>     
                        <v-row class="ma-0 px-1">
                            <v-radio-group hide-details hide-spin-buttons v-model="clientWidth" row>
                                <v-radio
                                    v-for="n in widthList"
                                    :key="n.text"
                                    :label="n.text"
                                    :value="n.value"
                                ></v-radio>
                            </v-radio-group>
                            <v-switch
                            hide-details hide-spin-buttons
                                v-model="tick"
                                :label="`刻度: ${tick?'开':'关'}`"
                                ></v-switch>
                        </v-row>                                    
                    </template>
                    <!-- 删除 -->                                    
                    <template v-slot:item.operation="{ item }">
                        <v-icon
                        v-show="item.index == colorBarParams.length && colorBarParams.length!=1"
                            small
                            @click.stop="del(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                    <!-- 修改每列 -->
                    <template v-slot:item.min="props">
                        <v-text-field    
                        style="max-width:60px"                                               
                            type="number"
                            v-model="props.item.min"                                                     
                            dense
                            hide-details
                            hint                        
                            single-line
                        ></v-text-field>
                    </template>
                    <template v-slot:item.max="props">
                        <v-text-field  
                        style="max-width:60px"                                                
                            type="number"
                            v-model="props.item.max"                                                     
                            dense
                            hide-details
                            hint                        
                            single-line
                        ></v-text-field>
                    </template>
                    <template v-slot:item.dis="props">
                        <v-text-field   
                            style="max-width:60px"                        
                            type="number"
                            v-model="props.item.dis"                                                     
                            dense
                            hide-details
                            hint                        
                            single-line
                        ></v-text-field>
                    </template>
                    </v-data-table>
                </v-row>
                <v-row class="ma-0 d-flex justify-space-between align-center">                    
                    <div style="position:absolute;width:226px;height:5px;" class="d-flex align-center justify-space-between">
                        <div v-for="index in 25" :style="`width:1px;height:${index % 5 == 0  || index == 0 ? 8:4 }px;background-color: rgba(0,0,0,.5);`"></div>
                    </div>
                    <div style="width:226px;height:5px;" class="d-flex flex-nowrap justify-center">                                                
                        <div v-for="i,index in currentColorKey.colors" :key="index" :style="`background:${i};flex: auto;`"></div>
                    </div> 
                    <v-menu  
                     min-width="162"                   
                     :items="colorKeys"                    
                    >
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn                                                                                 
                        v-bind="attrs"
                        v-on="on"
                        >
                        {{currentColorKey.text}}
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item-group v-model="currentColorKey">
                            <v-list-item                            
                                v-for="(item,index) in colorKeys"
                                :key="index" 
                                :value="item"
                            >
                                <v-list-item-title>{{item.text}}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>

                    </v-menu>
                </v-row>                
            </v-card>
        </v-menu>
    </v-container>
</template>
<script>
import colors from '@/plugins/datas/colors'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:"ColorBarConfig",
    props:{      
        dataKey:{

        },       
        disabled:{
            type:Boolean,
            default:false
        }        
    },    
    data(){
        return{
            unit:"",   
            openMenu:false,//菜单是否开启
            tick:false,
            widthList:[{
                text:"宽",
                value:15
            },{
                text:"稍宽",
                value:10
            },{
                text:"窄",
                value:5
            }],
            clientWidth:5,
            //色表卡            
            colorBarParams:[{
                index:1,
                min:0,
                max:1,
                dis:0.01
            }],
            headers:[{
                text:this.$i18n.t('components.colorBar.table.header.index'),
                value: 'index',
                sortable: false,
                groupable:false,
                width:'6%'
            },{
                text:this.$i18n.t('components.colorBar.table.header.min'),
                value: 'min',
                sortable: false,
                groupable:false,
                width:'14%'
            },{
                text:this.$i18n.t('components.colorBar.table.header.max'),
                value: 'max',
                sortable: false,
                groupable:false,
                width:'14%'
            },{
                text:this.$i18n.t('components.colorBar.table.header.dis'),
                value: 'dis',
                sortable: false,
                groupable:false,
                width:'14%'
            },{
                text:this.$i18n.t('components.colorBar.table.header.operation'),
                value: 'operation',
                sortable: false,
                groupable:false,
                width:'10%'
            },],         
            currentColorKey:{
                text:colors["jet"].name,
                value:"Jet",
                colors:colors["jet"].colors,
                colorList:colors["jet"].colorList
            },
            rangeInfo:[],            
            totalValue:0,
            valueInfo:[],
            colorScaleValue:[],    
            colorsBg:[],
            currentColorMode:0,
            updateN:false,
        }
    },
    watch:{
        openMenu:{
            handler(n){
                //重置未保存填写
                console.log(n,this.updateN)
                if(!n && this.updateN){                    
                    this.loadType();                    
                }                
                this.updateN = false;
            }
        },             
        dataKey(){       
            this.loadType(); 
        },  
        currentColorMode:{
            handler(n){
                this.updateN = true;
                this.resetExtentConfig(this.colorBarParams) 
                this.$emit('update')                                
            }
        },
        currentColorKey:{
            handler(n){
                this.updateN = true;
                this.resetExtentConfig(this.colorBarParams) 
                this.$emit('update')                                
            }
        },
        colorBarParams:{
            handler(n){
                this.updateN = true;
                //定义colorscale
                this.resetExtentConfig(this.colorBarParams) 
                this.$emit('update')
            },
            deep:true
        },clientWidth:{
            handler(){
                this.updateN = true;
            }
        },tick:{
            handler(){
                this.updateN = true;
            }
        }        
    },
    mounted(){  
        let that= this;
        this.$nextTick(()=>{
            that.loadType() 
        })        
        // setTimeout(()=>{         
        //     that.$emit('change',{
        //         colorBarParams:that.colorBarParams,
        //         currentColorKey:that.currentColorKey
        //     }) 
        // },600)
          
    },
    computed:{
         colorKeys:()=>{
            let keys = [];        
            for(var i in colors){                
                keys.push({
                    text:colors[i].name,
                    value:i,
                    colors:colors[i].colors,
                    colorList:colors[i].colorList,
                })
            }            
            return keys;
        },
        minWidth:()=>{
            return $help.isMobile() ? '100%':420
        }
       
    },
    methods:{ 
        loadType(){    
            //更新色系             
            let specificConfig = this.$store.state.storage_global.currentSite?.extendInfo?.colorBar ||  this.$store.state.storage_user.user?.extendInfo?.colorBar
            let _itemData = specificConfig?.find(a=>a.type == this.dataKey)     
            if(_itemData){
                let storageKey = "ColorBar_"+ _itemData.fullName;
                this.unit = _itemData.unit;
                if(localStorage.getItem(storageKey)){                            
                    let stroage = JSON.parse(localStorage.getItem(storageKey))
                    if($help.isNull(stroage.colorMode)){
                        localStorage.removeItem(storageKey)
                        this.colorBarParams = JSON.parse(JSON.stringify(_itemData.dataConfig));     
                        this.currentColorKey =JSON.parse(JSON.stringify(this.colorKeys.find(a=>{
                            return a.text == _itemData.colorkey;
                        })));  
                        this.currentColorMode = 1     
                    }                    
                    this.colorBarParams = JSON.parse(JSON.stringify(stroage.dataConfig));
                    this.currentColorKey = JSON.parse(JSON.stringify(this.colorKeys.find(a=>{
                        return a.text == stroage.colorkey;
                    })      ))
                    this.currentColorMode = stroage.colorMode   
                    this.clientWidth = stroage.clientWidth ? stroage.clientWidth:5;
                    this.tick = stroage.showTick                  
                }else{
                    this.colorBarParams = JSON.parse(JSON.stringify(_itemData.dataConfig))   
                    this.currentColorKey =JSON.parse(JSON.stringify(this.colorKeys.find(a=>{
                        return a.text == _itemData.colorkey;
                    })))  
                    this.currentColorMode = 1                                                
                }
            }else{
                this.clientWidth = 5;
                this.tick = false;
                for(let i in transferDataType) {
                    let itemData = transferDataType[i]
                    if(itemData.value == this.dataKey){
                        let storageKey = "ColorBar_"+ itemData.fullName;
                        this.unit = itemData.unit;
                        if(localStorage.getItem(storageKey)){
                            let stroage = JSON.parse(localStorage.getItem(storageKey))
                            if($help.isNull(stroage.colorMode)){
                                localStorage.removeItem(storageKey)
                                this.colorBarParams = JSON.parse(JSON.stringify(itemData.dataConfig));     
                                this.currentColorKey =JSON.parse(JSON.stringify(this.colorKeys.find(a=>{
                                    return a.text == itemData.colorkey;
                                })));  
                                this.currentColorMode = 1     
                            }
                            this.colorBarParams = JSON.parse(JSON.stringify(stroage.dataConfig));
                            this.currentColorKey = JSON.parse(JSON.stringify(this.colorKeys.find(a=>{
                                return a.text == stroage.colorkey;
                            })));   
                            this.currentColorMode = stroage.colorMode
                            this.clientWidth = stroage.clientWidth ? stroage.clientWidth:5;
                            this.tick = stroage.showTick   
                        }else{
                            this.colorBarParams = JSON.parse(JSON.stringify(itemData.dataConfig));     
                            this.currentColorKey =JSON.parse(JSON.stringify(this.colorKeys.find(a=>{
                                return a.text == itemData.colorkey;
                            })));  
                            this.currentColorMode = 1                                                         
                        }
                    }
                }                                             
            }  
        },
        resetExtentConfig(n){
            let vai = false;
            for(var i in this.colorBarParams){
                let _item = this.colorBarParams[i];
                if(_item.dis == 0){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 距离数据不可为0`
                    })
                    return;
                }
                if(Number(_item.max) == Number(_item.min)){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大值要比最小值大`
                    })
                    return;
                }     
                if(Number(_item.max) < Number(_item.min)){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大值要比最小值大`
                    })
                    return
                }           
                if((Number(_item.max) - Number(_item.min))/_item.dis < 2){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最小分段为5段,请确保（最大值-最小值）/距离 >= 2`
                    })
                    return;
                }
                if((Number(_item.max) - Number(_item.min))/_item.dis > 100){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大分段为50段,请确保（最大值-最小值）/距离 <= 100`
                    })
                    return;
                }
                
                if(i != (this.colorBarParams.length -1)){
                    let _next = this.colorBarParams[Number(i)+1];
                    if(Number(_item.max)!= Number(_next.min)){
                        openNotice({
                            type:"error",
                            text:`数据段必须连续，请确保第${Number(i)+1}行最大值为第${Number(i)+2}行最小值`
                        })
                        return
                    }                    
                }
                vai = true;                
            }
            if(!vai){
                return;
            }
            var colorScaleValue = [];
            let _colors = this.currentColorKey.colors;  
            let colors = [];                      

            let total = 0;
            let objs = []                      
            n.forEach(a=>total += (Number(a.max) - Number(a.min))/a.dis)          
            let colorSpan = Math.floor(_colors.length / total);            
            if(this.currentColorMode == 0){
                for(var i =0;i<=_colors.length;i+= colorSpan -1){
                    if(i == Math.floor(_colors.length/2)){                       
                    }else{
                        colors.push(_colors[i])
                    }                    
                } 
            }else{
                colors = _colors;
            }
            if(window.log){
                console.log(colors)
            }            
            this.colorsBg = colors;
            let sp = Number(n[n.length -1].max) - Number(n[0].min);
            let spl = sp/this.currentColorKey.colorList.length   
            colorScaleValue.push([0,colors[0]])
            n.forEach((a)=>{  
                if(this.currentColorMode == 0){      
                    objs.push({
                        index:a.index,
                        value:(Number(a.max) -Number(a.min))/Number(a.dis),
                        min:Number(a.min),
                        max:Number(a.max)
                    });             
                } else{
                    this.currentColorKey.colorList.forEach((b,i)=>{
                        let min = spl * i + Number(n[0].min);
                        let max = spl * (i+1)+ Number(n[0].min)                        
                        if(min >= Number(a.min)){   
                            if(Number(a.max) > max){
                                let _min =Number(objs[objs.length-1]?.max)
                                min = _min?_min:min;                            
                                objs.push({
                                    value:(max - min)/Number(a.dis),
                                    min:min,
                                    max:max,                                    
                                })
                            }                          
                        }                                                                
                    })   
                    let min = objs[objs.length-1]?.max;       
                    objs.push({
                        index:a.index,
                        value:(Number(a.max) -(min?min:Number(a.min)))/Number(a.dis),
                        min:min?min:Number(a.min),
                        max:Number(a.max)
                    })                        
                }                                                           
            })              
            objs = objs.sort((a,b)=>{
                return Number(a.max) - Number(b.max)
            }) 
            let span = 0
            let spanV = 0
            if(this.currentColorMode == 0){
                objs.forEach((a,i)=>{
                    let startIndex = Math.floor(span * (colors.length -1)/total)
                    span += Number(a.value);  
                    spanV += (Number(a.max) -Number(a.min));                                      
                    let endIndex = Math.floor(span * (colors.length -1)/total)
                    a.colors = colors.slice(startIndex,endIndex)                          
                })
                colorScaleValue = [];
                colors.forEach((a,i)=>{                                        
                    if(i < colors.length-1){
                        colorScaleValue.push([i/(colors.length-1),colors[i]])
                        colorScaleValue.push([(i+1)/(colors.length-1),colors[i]])
                    }                                     
                })     
                                                      
            }else{
                objs.forEach((a,i)=>{
                    let startIndex = Math.floor(span * (colors.length -1)/total)
                    span += Number(a.value);  
                    spanV += (Number(a.max) -Number(a.min));                                      
                    let endIndex = Math.floor(span * (colors.length -1)/total)
                    let _color =colors[Math.floor(span * (colors.length -1)/total)]                           
                    colorScaleValue.push([spanV/sp ,_color]) 
                    a.colors = colors.slice(startIndex,endIndex)                          
                })
            }      
            this.colorScaleValue = colorScaleValue;            
            this.rangeInfo = objs
            this.totalValue = total;
            this.valueInfo = []
            let start = 0;
            n.forEach((a,i)=>{     
                this.valueInfo.push({
                    start:(Number(a.max) -Number(a.min))/Number(a.dis),
                    value:Number(a.min),
                    r:(total-start)/total,
                    h: (Number(a.max) -Number(a.min))/Number(a.dis)/total
                })
                start += ((Number(a.max) -Number(a.min))/Number(a.dis))        
            })
            this.valueInfo.push({
                start:0,
                value:Number(objs[objs.length-1].max),
                r:0,
                h:0/total
            })
        },
        save(){
            //保存色标卡设置
            //验证
            let vai = false;
            for(var i in this.colorBarParams){
                let _item = this.colorBarParams[i];
                if(_item.dis == 0){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 距离数据不可为0`
                    })
                    return;
                }
                if(Number(_item.max) == Number(_item.min)){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大值要比最小值大`
                    })
                    return;
                }     
                if(Number(_item.max) < Number(_item.min)){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大值要比最小值大`
                    })
                    return
                }           
                if((Number(_item.max) - Number(_item.min))/_item.dis < 2){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最小分段为5段,请确保（最大值-最小值）/距离 >= 2`
                    })
                    return;
                }
                if((Number(_item.max) - Number(_item.min))/_item.dis > 100){
                    openNotice({
                        type:"error",
                        text:`第${Number(i)+1}行 最大分段为50段,请确保（最大值-最小值）/距离 <= 100`
                    })
                    return;
                }
                
                if(i != (this.colorBarParams.length -1)){
                    let _next = this.colorBarParams[Number(i)+1];
                    if(Number(_item.max)!= Number(_next.min)){
                        openNotice({
                            type:"error",
                            text:`数据段必须连续，请确保第${Number(i)+1}行最大值为第${Number(i)+2}行最小值`
                        })
                        return
                    }                    
                }
                vai = true;                
            }
            if(!vai){
                return;
            }
            this.resetExtentConfig(this.colorBarParams)            
            this.$emit('update') 
            for(let i in transferDataType) {
                let itemData = transferDataType[i]
                if(itemData.value == this.dataKey){
                    let storageKey = "ColorBar_"+ itemData.fullName;
                    localStorage.removeItem(storageKey)
                    localStorage.setItem(storageKey,JSON.stringify({
                        dataConfig:this.colorBarParams,
                        colorkey:this.currentColorKey.text,
                        colorMode:this.currentColorMode,
                        clientWidth:this.clientWidth,
                        showTick:this.tick
                    }))
                }
            }
            this.openMenu = false
        },      
        //色表卡表格
        del(){
            if(this.colorBarParams.length == 1){
                openNotice({
                    type:'error',
                    text:"必须保留一行参数"
                })
                return
            }
            this.colorBarParams.pop();
            this.colorBarParams = this.colorBarParams.sort((a,b)=>{
                return a.min - b.min
            }).map((a,i)=>{
                return {
                    index:i+1,
                    min:a.min,
                    max:a.max,
                    dis:a.dis
                }
            })
        },
        newParams(){            
            //添加一行新的，如果已经有五行则不添加
            if(this.colorBarParams.length >= 5){
                openNotice({
                    type:'error',
                    text:"最多添加五行"
                })
                return
            }
            this.colorBarParams = this.colorBarParams.sort((a,b)=>{
                return a.min - b.min
            }).map((a,i)=>{
                return {
                    index:i+1,
                    min:a.min,
                    max:a.max,
                    dis:a.dis
                }
            })
            this.colorBarParams.push({
                index:this.colorBarParams.length+1,
                min:this.colorBarParams[this.colorBarParams.length-1].max,
                max:this.colorBarParams[this.colorBarParams.length-1].max+1000,
                dis:500
            })
        },
        filterMinMax(value,item){                           
            console.log(value,item)          
        },
        reset(){
            for(let i in transferDataType) {
                let itemData = transferDataType[i]
                if(itemData.value == this.dataKey){
                    let storageKey = "ColorBar_"+ itemData.fullName;
                    localStorage.removeItem(storageKey)
                    this.colorBarParams = itemData.dataConfig     
                    this.currentColorKey =this.colorKeys.find(a=>{
                        return a.text == itemData.colorkey;
                    })   
                }
            }                   
            this.currentColorMode = 1
        },
        getMin(props){            
            if(props.header ){     
                if(props.index == 0){
                    if(props.header.value == "min"){
                        props.value = props.value < -1000000 ?  -1000000:props.value;
                        return -1000000;
                    }
                    if(props.header.value == "max"){
                        if(props.item.min!==""){
                            props.value = props.value < props.item.min+ 0.0001 ? props.item.min+ 0.0001:props.value;
                            return props.item.min+ 0.0001
                        }
                    }
                    if(props.header.value == "dis"){
                        if(props.item.min !=="" && props.item.max !==""){
                            let _val = (props.item.max - props.item.min) / 100;
                            props.value = props.value <  _val?_val:props.value
                            return _val
                        }
                    }
                }                           
                else{
                    if(props.header.value == "min"){
                        props.value = props.value  < this.colorBarParams[props.index -1].max?this.colorBarParams[props.index -1].max:props.value;
                        return this.colorBarParams[props.index -1].max;
                    }
                    if(props.header.value == "max"){
                        if(props.item.min!==""){
                            let _val =  props.item.min+ 0.0001;
                            props.value = props.value <  _val ? _val: props.value
                            return _val
                        }
                    }
                    if(props.header.value == "dis"){
                        if(props.item.min !=="" && props.item.max !==""){
                            let _val = (props.item.max - props.item.min) / 100
                            props.value = props.value <  _val ? _val: props.value
                            return _val
                        }                            
                    }
                }
            }
           
           
        },
        getMax(props){
             if(props.header){                                                 
                if(props.index == (this.colorBarParams.length -1)){
                    if(props.header.value == "min"){
                        if(props.item.max !== ""){
                            let _val =  props.item.max - 0.0001;
                            props.value = props.value >  _val ? _val: props.value
                             return _val
                        }
                    }
                    if(props.header.value == "max"){
                        props.value = props.value >  1000000 ? 1000000: props.value
                        return 1000000
                    }
                    if(props.header.value == "dis"){
                        if(props.item.min !=="" && props.item.max !==""){
                            let _val =  (props.item.max - props.item.min) / 100;
                            props.value = props.value >  _val ? _val: props.value
                            return _val
                        }
                    }
                }                           
                else{
                    if(props.header.value == "min"){
                        if(props.item.max !== ""){
                            let _val =  props.item.max - 0.0001;
                            props.value = props.value >  _val ? _val: props.value
                             return _val
                        }
                    }
                    if(props.header.value == "max"){
                        let _val = this.colorBarParams[props.index +1].min;
                        props.value = props.value >  _val ? _val: props.value
                        return _val 
                    }
                    if(props.header.value == "dis"){                        
                         if(props.item.min !=="" && props.item.max !==""){
                            let _val =  (props.item.max - props.item.min) / 2
                            props.value = props.value >  _val ? _val: props.value
                            return _val                             
                        }
                    }
                }
            }
        },
        getRgb(_val,cb){
            if(!cb){
                return;
            }            
            let colors = this.currentColorKey.colors;
            if(!colors||colors.length == 0)     
            {
                return;
            }
            if(_val == null||typeof(_val) == 'undefined' || isNaN(_val) || _val == 'NaN' || _val == 'NAN' ){
                cb([0,0,0,0]);        
                return;        
            }
            let val = Number(_val);
            if(val <= this.rangeInfo[0].min){
                cb(colors[0].match(/(\d(\.\d+)?)+/g).concat(1));
                return;
            }else if(val >= this.rangeInfo[this.rangeInfo.length -1].max){              
                cb(colors[colors.length -1].match(/(\d(\.\d+)?)+/g).concat(1));
                return;
            }else{
                let item = this.rangeInfo.find((a,i)=>{                
                    return a.min <= val && a.max > val
                })
                if(item){
                    let colorIndex =   Math.floor((val - item.min)/(item.max - item.min) * item.colors.length)
                    if(!item.colors[colorIndex]){
                        debugger                        
                    }                      
                    cb(item.colors[colorIndex].match(/(\d(\.\d+)?)+/g).concat(1)) 
                    return;                            
                }
                else{
                    debugger
                }
            }
        },
        con(){
           console.log(111) 
        }
    }
}
</script>
<style scoped lang="scss">
.mapColorBar{
    z-index: 2;
    position:relative;
    top: 0px;
    right: 0px;  
    height: 100%;  
    display: flex;
    justify-content: center;
}
.setBar{
    position: absolute;
    width: 10px;
    height: calc(100% + 60px);
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom:10px;
}
</style>