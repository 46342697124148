//登录
import as from "@/plugins/helper/axiosHelper";
import * as url from './url'
//登录
export async function loginApi(params){
   return as.post(url.loginURL,params)
}

//登出
export async function loginOutApi(params){
   return as.remove(url.loginURL,params)
}

//文件上传
export async function upLoadApi(params){
    let _url = params.url ? url.uploadAndDownLoadURL + params.url:url.uploadAndDownLoadURL
    as.get(_url,{success:(d)=>{
        let upUrl = d;
        let files = params.files;            
        as.upToMinio(upUrl,files,
        {
            success:(f)=>{
                if(params.success){
                    params.success(url.uploadMinioURL,f)
                }
            }
        })
    },function(d){

    }})
 }

 //文件下载
export async function downloadApi(params){
    let _url = params.url ? url.uploadAndDownLoadURL + params.url:url.uploadAndDownLoadURL
    as.get(_url,{success:(d)=>{
        let upUrl = d;
        as.downToMinio(upUrl,params)
    },function(d){

    }})
 }
//获取图片
export async function getImageApi(url,success){
   as.getFile(url,success)
}


 //获得地理位置
 export async function getGisApi(params){
   as.get(url.Gis + '/reverse',params)
 }

  //获得地理位置
  export async function getTDGisApi(params){
   as.get(url.Gis + '/tiandi/reverse',params)
 }



  //字典表
//获取基础字典
export async function getBaseDicApi(params){          
    return as.get(url.dicURL+"/"+params.typeId,params)
}

 //获取刷新
export async function getRefreshTokenApi(params){
    return as.get(url.loginURL,params)
 }

//获取用户信息
export async function getUserInfoApi(params){
    as.get(url.userURL+'/userinfo',params)
}
//忘记密码
export async function forgetPasswordApi(params){
   as.get(url.userURL+'/'+params.account+'/password',params)
}
//更新用户信息
export async function editUserInfoApi(params){
   as.patch(url.userURL+'/'+params.id+'/self',params)
}
//修改密码
export async function editUserPasswordApi(params){
   as.patch(url.userURL+'/'+params.id+'/password',params)
}

 //站点
//获取站点列表不分页
export async function getSitesListApi(params){
    return as.get(url.siteURL+'/list',params);
}

export async function getSiteExchangeStringAsync(params){
   as.get(url.siteURL + '/exchangestring',params)
}

//国控点
export async function getMonitorPointsApi(params){
    as.get(url.monitorPointURL,params)
}
export async function addMonitorPointsApi(params){
    as.post(url.monitorPointURL,params)
}

export async function editMonitorPointApi(params){
    as.patch(url.monitorPointURL+'/'+params.id,params)
}

export async function deleteMonitorPointApi(params){
    as.remove(url.monitorPointURL+'/'+params.id,params)
}


 //案例
 export async function getCasesApi(params){
    as.get(url.caseURL,params)
 }
 //查询单个
 export async function getCaseApi(params){
    as.get(url.caseURL+'/'+params.id,params)
 }
 //查询前一个后一个
 export async function getCaseNextApi(params){
   as.get(url.caseURL+'/'+params.id+'/next',params)
}

 export async function addCaseApi(params){
    as.post(url.caseURL,params)
 }
 export async function editCaseApi(params){
   as.patch(url.caseURL+'/'+params.id,params)
}
 export async function publishCaseApi(params){
    as.patch(url.caseURL+'/publish/'+params.id,params)
 }
 export async function deleteCaseApi(params){
    as.remove(url.caseURL+'/'+params.id,params)
 }
 //添加案例为模板
export async function addCaseToTemplate(params){
   as.post(url.caseURL+'/'+params.id+'/template',params)
}  
//查找模板
export async function getCaseTemplateApi(params){
   as.get(url.caseURL+'/template/'+params.id,params)
}
//分页获取
export async function getCaseTemplatesApi(params){
   as.get(url.caseURL+'/template',params)
}
export async function addCaseTemplateApi(params){
   as.post(url.caseURL+'/template',params)
}
export async function editCaseTemplateApi(params){
  as.patch(url.caseURL+'/template/'+params.id,params)
}
export async function deleteCaseTemplateApi(params){
   as.remove(url.caseURL+'/template/'+params.id,params)
}


 //报告
 export async function getReportsApi(params){
    as.get(url.reportURL,params)
 }
 export async function addReportApi(params){
    as.post(url.reportURL,params)
 }
 export async function editReportApi(params){
    as.patch(url.reportURL+'/'+params.id,params)
 }
 export async function deleteReportApi(params){
    as.remove(url.reportURL+'/'+params.id,params)
 }

 //日报
 export async function getDailyReportsApi(params){
    as.get(url.dailyReportURL,params)
 }
 export async function getDailyReportApi(params){
    as.get(url.dailyReportURL+'/'+params.id,params)
 }
 export async function addDailyReportApi(params){
    as.post(url.dailyReportURL,params)
 }
 export async function editDailyReportApi(params){
    as.patch(url.dailyReportURL+'/'+params.id,params)
 }
 export async function deleteDailyReportApi(params){
    as.remove(url.dailyReportURL+'/'+params.id,params)
 }

 //获取站点最新扫描方式
 export async function getLastestRawApi(params){
   return as.get(`${url.siteURL}/${params.id}/scan/type`,params)
}


//sps最新ppi数据
export async function getPlsLatestDataApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/pls/sps/latest`,params)
}

 //pls点位数据
 export async function getPlsPositionDataApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/pls/sps/${params.period}/position/data`,params)
}

 //pls点位数据趋势
 export async function getPlsPointDataApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/pls/sps/position/range`,params)
}


 //pls点位数据趋势
 export async function getVlsPointDataApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/viz/position/range`,params)
}


 //获取站点周期数据
 //原始数据
export async function getRawApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/raw`,params)
}

//获取vls 周期id
export async function getVlsVizPeriodListApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/period/viz`,params)
}

 //vls周期数据
export async function getVlsVizByPeriodApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/viz/${params.period}`,params)
}

 //vls最近周期数据
export async function getVlsVizLatestApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/viz/latest`,params)
}

 //vls点位数据
 export async function getVlsPositionDataApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/viz/${params.period}/position/data`,params)
}

 //vls最近周期数据
export async function getVlsVizApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/viz`,params)
}

 //vlsave周期数据
 export async function getVlsAveApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/ave`,params)
}

 //vlsave周期数据 多站点
 export async function getSiteListVlsAveApi(params){
   return as.get(`${url.siteURL}/sites/data/vls/ave`,params)
}

 //vlsrcs周期数据 包含下一镜像，上一镜像
 export async function getVlsRcsListApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/rcs`,params)
}

 //vlsrcs周期数据
export async function getVlsRcsByPeriodApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/rcs/${params.period}`,params)
}
 //vlsrcs周期数据最近一周期数据
export async function getVlsRcsByLatestApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vls/rcs/latest`,params)
}
 //vls多站点报警获取
 export async function getVlsAlarmBySiteIdsApi(params){
   as.get(url.siteURL+'/sites/data/vls/alarm',params)
 }

 //vls报警获取
 export async function getVlsAlarmApi(params){
   as.get(url.siteURL+'/'+params.id+'/data/vls/alarm',params)
 }
 //衍生
export async function getRadialApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/radial`,params)
}
//垂直数据
 export async function getPLSFIXDataApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/pls/${params.dataType}`,params)
}
 //plsrcs时间范围数据
export async function getPlsRcsListApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/pls/rcs`,params)
}
 //plsrcs周期数据最近一周期数据
 export async function getPlsRcsByLatestApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/pls/rcs/latest`,params)
}
 //实时监测报警点获取
 export async function getAlarmPointsApi(params){
   as.get(url.siteURL+'/'+params.id+'/data/alarmpoint/'+params.filter,params)
 }

  //实时监测测风报警获取
export async function getProfileAlarmApi(params){
   as.get(url.siteURL+'/'+params.id+'/data/wls/profilealarm',params)
 }

   //多站点实时测风报警获取
   export async function getSitesProfileAlarmApi(params){
      as.get(url.siteURL+'/sites/data/wls/profilealarm',params)
    }

 //历史数据报警点获取
 export async function getAlarmPointComputedsApi(params){
   as.get(url.siteURL+'/'+params.id+'/data/alarmpoint/computed/'+params.filter,params)
 }

 //历史数据报警点地理位置获取
 export async function getAlarmPointGisListApi(params){
   as.post(url.siteURL+'/'+params.id+'/data/alarmpoint/gis',params)
 }
  //多站点实时监测报警点获取
 export async function getSitesAlarmPointsApi(params){
   as.get(url.siteURL+'/sites/data/alarmpoint/'+params.filter,params)
 }
   //报警点历史拍照
   export async function getSiteAlarmImageApi(params){
      as.get(url.siteURL+'/'+params.id+'/data/alarm/image',params)
    }
   

 //获取历史图片
export async function getHistoryImagesApi(params){
   as.get(url.siteURL+'/'+params.id+'/data/image',params)
}

//获取走航批次数据
export async function getAgtInfosApi(params){
   as.get(url.siteURL+'/'+params.id+'/data/agtinfo',params)
}
//获得批次雷达扫描数据（包含高度信息）
export async function getAgtDetailApi(params){   
   as.get(url.siteURL+'/'+params.id+'/data/agtinfo/'+params.agtId+'/agtdetail',params)
}
//获得批次采集设备数据（不含高度信息）
export async function getAgtExternalApi(params){
   as.get(url.siteURL+'/'+params.id+'/data/agtinfo/'+params.agtId+'/agtexternal',params)
}

//获取消息
export async function getAlarmMessagesApi(params){
   as.get(url.messageURL + '/my/alarm',params)
}//获取消息


export async function getNoticeMessagesApi(params){
   as.get(url.messageURL + '/my/notice',params)
}

//发送实时报警
export async function sendAlarmToWxUserApi(params){
   as.post(url.reciverUrl+'/alarm/send/'+params.templateId,params)
}
//报警消息通知设置
export async function getReciverConfigApi(params){
   as.get(url.reciverConfigUrl,params)
}
// export async function getDailyReportApi(params){
//    as.get(url.reciverConfigUrl+'/'+params.id,params)
// }
export async function addReciverConfigApi(params){
   as.post(url.reciverConfigUrl,params)
}
export async function editReciverConfigApi(params){
   as.patch(url.reciverConfigUrl+'/'+params.id,params)
}
export async function deleteReciverConfigApi(params){
   as.remove(url.reciverConfigUrl+'/'+params.id,params)
}
//获取微信人员信息
export async function getReciverApi(params){
   as.get(url.reciverUrl + '/'+params.id,params)
}

//获取统计信息
export async function getReportCaseStatisticsApi(params){
   as.get(url.statisticsURL + '/alarm/report',params)
}

export async function getRoadStatisticsApi(params){
   as.get(url.statisticsURL + '/alarm/road/'+params.date,params)
}

export async function getSiteStatisticsApi(params){
   as.get(url.statisticsURL + '/alarm/site/'+params.date,params)
}

export async function getAlarmPointStatisticsApi(params){
   as.get(url.statisticsURL + '/alarm/point/',params)
}

export async function getAlarmVlsStatisticsApi(params){
   as.get(url.statisticsURL + `/alarm/vls/${params.date}/info`,params)
}

//外场设备
export async function getExternalDevicesApi(params){
   as.get(url.externalDevice,params)
}


//测风
 //wls最近周期数据
export async function getWlsRadialApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/wls/radial/${params.period}/${params.scanType}`,params)
}
export async function getWlsShearApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/wls/shear/${params.period}/${params.scanType}`,params)
}
export async function getWlsQuiverApi(params){
   as.allGet([`${url.siteURL}/${params.id}/data/wls/quiver/${params.period}/${params.scanType}`,`${url.siteURL}/${params.id}/data/wls/quiver/back/${params.period}/${params.scanType}`],params)
   //  as.get(`${url.siteURL}/${params.id}/data/wls/quiver/${params.period}`,params)
}

export async function getWlsShearByScanModeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/wls/shear/${params.scanType}`,params)
}
export async function getWlsProfileApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/wls/profile`,params)
}

export async function getWlsRadialByTimeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/wls/radial`,params)
}

export async function getWlsRadialByTimeRangeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/wls/radial/range`,params)
}


export async function getWlsShearByTimeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/wls/shear`,params)
}

export async function getWlsQuiverByTimeApi(params){
   as.allGet([`${url.siteURL}/${params.id}/data/wls/quiver`,`${url.siteURL}/${params.id}/data/wls/quiver/back`],params)   
}
//获取控点数据
export async function getMonitorPointDataApi(params){
   if(params.district == "xcz"){
      //乡村镇
      as.post(url.MonitorPointExternd1+'/common/getxczdata',params)
   }else if(params.district == 'gk'){
      //国控点
      as.post(url.MonitorPointExternd1+'/common/getgkdata',params)
   }  
}

 //vwls最近周期数据
 export async function getVwlsDVCMApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/dvcm`,params)
}

 //vwls最近周期数据
 export async function getVwlsVizApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/viz`,params)
}

export async function getVwlsRcsDataApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/prr`,params)
}


 //vwls最近周期数据
export async function getVwlsRadialApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/radial/${params.period}/${params.scanType}`,params)
}
export async function getVwlsShearApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/shear/${params.period}/${params.scanType}`,params)
}
export async function getVwlsQuiverApi(params){
   as.allGet([`${url.siteURL}/${params.id}/data/vwls/quiver/${params.period}/${params.scanType}`,`${url.siteURL}/${params.id}/data/wls/quiver/back/${params.period}/${params.scanType}`],params)
   //  as.get(`${url.siteURL}/${params.id}/data/wls/quiver/${params.period}`,params)
}

export async function getVwlsShearByScanModeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/shear/${params.scanType}`,params)
}
export async function getVwlsProfileApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/profile`,params)
}

export async function getVwlsRadialByTimeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/radial`,params)
}

export async function getVwlsRadialByTimeRangeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/radial/range`,params)
}


export async function getVwlsShearByTimeApi(params){
   return as.get(`${url.siteURL}/${params.id}/data/vwls/shear`,params)
}

export async function getVwlsQuiverByTimeApi(params){
   as.allGet([`${url.siteURL}/${params.id}/data/vwls/quiver`,`${url.siteURL}/${params.id}/data/wls/quiver/back`],params)   
}

export async function getMarkTypeListApi(params){
   as.get(url.markType,params)
}

export async function addMarkTypeApi(params){
   as.post(url.markType,params)
}
export async function editMarkTypeApi(params){
   as.patch(url.markType+'/'+params.id,params)
}
export async function deleteMarkTypeApi(params){
   as.remove(url.markType+'/'+params.id,params)
}

export async function getMarkInfoListApi(params){
   as.get(url.markInfo,params)
}
export async function addMarkInfoApi(params){
   as.post(url.markInfo,params)
}
export async function editMarkInfoApi(params){
   as.patch(url.markInfo+'/'+params.id,params)
}
export async function deleteMarkInfoApi(params){
   as.remove(url.markInfo+'/'+params.id,params)
}

export async function getMarkRecordsListApi(params){
   as.get(url.markRecords,params)
}
export async function addMarkRecordsApi(params){
   as.post(url.markRecords,params)
}
export async function editMarkRecordsApi(params){
   as.patch(url.markRecords+'/'+params.id,params)
}
export async function deleteMarkRecordsApi(params){
   as.remove(url.markRecords+'/'+params.id,params)
}

//地理编码和天气
export async function getGeocode(local,cb){   
   let xhr = new XMLHttpRequest();  
   xhr.open('get',`http://dbcenter.gyquantum.com/_AMapServiceGD/v3/geocode/regeo?key=309f07ac6bc48160e80b480ae511e1e9&s=rsv3&language=zh_cn&location=${local}&radius=1000&platform=JS&logversion=2.0&appname=https%3A%2F%2Flbs.amap.com%2Fdemo%2Fjavascript-api%2Fexample%2Fgeocoder%2Fregeocoding&sdkversion=1.4.24`)
   xhr.onreadystatechange=()=>{                
      if(xhr.readyState  === 4) {            
         if(xhr.status === 200) {
            cb(JSON.parse(xhr.response))
         }
      }
   }
   xhr.send()
}

export async function getWeather(city,cb){
   let _url= `http://dbcenter.gyquantum.com/_AMapServiceGD/v3/weather/weatherInfo?key=309f07ac6bc48160e80b480ae511e1e9&s=rsv3&platform=JS&appname=https%3A%2F%2Flbs.amap.com%2Fdemo%2Fjavascript-api%2Fexample%2Fgeocoder%2Fregeocoding&sdkversion=1.4.24&city=${city}&output=json`
   let xhr = new XMLHttpRequest();  
   xhr.open('get',_url)
   xhr.onreadystatechange=()=>{                
      if(xhr.readyState  === 4) {            
         if(xhr.status === 200) {
            cb(JSON.parse(xhr.response))
         }
      }
   }
   xhr.send()
}


