<template>
    <v-card class="ma-0 pa-0" style="height: 284px;width: 284px;">
        <div ref="scanMap" class="map noevent"></div>
        <ColorBarConfig :dataKey="2" ref="colorBar" style="position: absolute;display: none !important;overflow: hidden;z-index: -1;" /> 
    </v-card>
</template>
<script>
import * as echarts from "echarts"
import {getAlarmPointsApi,getHistoryImagesApi,getGisApi,getReciverApi,sendAlarmToWxUserApi,getAlarmPointComputedsApi,getPlsLatestDataApi} from '@/api' 
import "echarts-extension-amap"
import transferDataType from '@/plugins/datas/transferDataType'
import { uploadMinioURL } from '@/api/url'

import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
export default {
      name: 'pls.realtime.pre',
      components: {
        ColorBarConfig
      },
      watch:{
        "$store.state.storage_global.currentSite":{
            handler(n,v){   
                if(this.$store.state.storage_global.currentSite.extendInfo){            
                    let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
                    this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
                }else{
                    this.scanDistance = 6
                }        
                this.loadMap();
                this.loadLidarData(); 
                if(this.lidarDatas.length > 0 && n.siteId != this.lidarDatas[0].siteId){
                    this.lidarDatas = [];          
                }    
                this.watchData(n.siteId)   
            }
        }
      },
      data(){
        return {
            canvasLayer:null,
            canvas:null,
            ctx:null,
            map:null,
            siteMarker:null,
            mapEchart:null,
            currentWatch:null,
            lidarDatas:[],
            hiddenDom:false,
            scanDistance:6
        }
      },
      beforeDestroy(){
        if(this.currentWatch){
            this.currentWatch()
        }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },  
    beforeUnmount(){
        if(this.currentWatch){
            this.currentWatch()
        }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    }, 
    mounted(){   
        var that = this;  
        if(this.$store.state.storage_global.currentSite.extendInfo){            
            let extendInfo = JSON.parse(this.$store.state.storage_global.currentSite.extendInfo)                
            this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;            
        } 
        var target = that.$refs.scanMap  
        if (document.hidden) {
                that.hiddenDom = true;
                // 清除      
                that.$nextTick(()=>{                
                    that.mapEchart = echarts.getInstanceByDom(target);                    
                    if(that.mapEchart != null){
                        that.mapEchart.clear();                   
                    } 
                })     
                
            } else {
                that.hiddenDom = false;
                // 开启 
                that.$nextTick(()=>{
                    that.loadMap()  
                    that.loadLidarData();    
                })                                 
            }
    },
      methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = this.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{                                            
                if(n.SiteId == that.$store.state.storage_global.currentSite.siteId && n.DataType == transferDataType['sps'].value && (n.ScanMode == 0 || n.ScanMode == "PPI")){                           
                    var json =JSON.parse(JSON.stringify(n.jsonData)); 
                                                  
                    if(that.lidarDatas.length > 0 &&(json[0].period != that.lidarDatas[0].period || n.DataType != that.lidarDatas[0].dataType)){
                        //更新预览
                        // let canvas = that.$refs["prePriod"]
                        // let ctx = canvas.getContext('2d')
                        // ctx.clearRect(0,0,400,400)
                        // if(that.imageData){
                        //     ctx.putImageData(that.imageData,0,0)                         
                        // }                    
                        // that.periodTime = new Date(that.lidarDatas[0].dataTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss")
                        if(that.ctx){                                
                            that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)
                        }  
                        if(!that.hiddenDom){   
                            that.loadLidarData();                                       
                            //that.draw()              
                        }      
                        that.lidarDatas = [];   
                                                        
                    }                                                                                                
                    that.lidarDatas = that.lidarDatas.concat(json) 
                    that.lidarDatas = that.lidarDatas.sort((a,b)=>{
                        return new Date(a.dataTime) - new Date(b.dataTime)
                    })
                    // if(!that.hiddenDom){                                        
                    //         that.draw()              
                    //     }     
                                
                                                      
                }
            }, {immediate: true })
        },   
        loadMap(){
            let target = this.$refs.scanMap; 
            if(!target){
                return
            }
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoom = 11.2;            
            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            mapEchart.clear()
            if(this.canvasLayer){
                this.map.remove(this.canvasLayer)
                this.canvasLayer = null;
            }
            let site = this.$store.state.storage_global.currentSite;
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    center:center ,
                    rotateEnable:true,
                    pitchEnable:true,   
                    isHotspot:false,             
                    pitch: 0,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;                 
            this.map = mapEchart.getModel().getComponent('amap').getAMap();
            this.siteMarker= new AMap.Marker({
                content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,  // 自定义点标记覆盖物内容
                position:center, // 基点位置
                offset: new AMap.Pixel(-8, -20), // 相对于基点的偏移位置                                    
            }); 
            this.map.add(this.siteMarker) 
        },
        loadLidarData(){
            var that = this; 
            let _width = 100 * this.scanDistance;           
            getHistoryImagesApi({
                id:this.$store.state.storage_global.currentSite.siteId,
                params:{
                    PageIndex:1,
                    PageSize:1,
                    DirTypeId:2   
                },
                success:(d)=>{                
                    var imgPath =  `${uploadMinioURL}${d.Data[0].DirBucketName}/${d.Data[0].DirObjectName}`
                    let time = new Date(d.Data[0].DirTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss")
                    if(!that.canvas){
                        let canvas = document.createElement('canvas')
                        //canvas = this.$refs.polar;
                        let ctx =canvas.getContext("2d"); 
                        canvas.width = _width;
                        canvas.height = _width;               
                        that.canvas = canvas;
                        that.ctx = ctx;
                    }
                    that.canvas.width = _width;
                    that.canvas.height = _width; 
                    let img = new Image(_width,_width)
                    img.crossOrigin ="anonymous"
                    let arr=[]
                    let imgParams = imgPath.match(/(?<=\@).*?(?=\@)/g);
                    if(!imgParams){
                        arr =$help.getLayerMapPosition(90,that.scanDistance,that.center)                                      
                    }else{
                        let w = Number(imgParams[0].split("_")[0]);
                        let vAngle = Number(imgParams[0].split("_")[1]);                                        
                        arr =$help.getLayerMapPosition(vAngle,that.scanDistance,that.center,w/500)                                                              
                    }
                    img.src = imgPath;
                    var bounds = new AMap.Bounds(arr[0],arr[1])   
                    if(!that.canvasLayer){
                        that.canvasLayer = new AMap.CanvasLayer({
                            canvas: that.canvas,
                            bounds: bounds,  
                            opacity:1,  
                            zooms: [2, 30]            
                        });        
                        that.map.add(that.canvasLayer);
                    }else{
                        that.canvasLayer.setBounds(bounds)                
                    }                
                    img.onload=(f)=>{    
                        if(that.canvasLayer){
                            that.ctx.clearRect(0,0,_width,_width)            
                            that.ctx.drawImage(img,0,0,_width,_width);                            
                            that.canvasLayer.reFresh();
                        }
                        //更新显示时间   
                        setTimeout(() => {
                            that.drawTimeText(time)                            
                        }, 600);                         
                           
                    }                    
                }
            })
        },draw(){        
            var that = this;   
            if(that.lidarDatas.length == 0 || !this.map){                
                return
            }
            if(!that.lidarDatas || that.lidarDatas.length == 0){
                    return;
            }
            if(!that.hiddenDom){                                                           
                let _width = 400;//包含设置像素

                if(!that.canvas){
                    let canvas = document.createElement('canvas')
                    //canvas = this.$refs.polar;
                    let ctx =canvas.getContext("2d"); 
                    canvas.width = _width;
                    canvas.height = _width;               
                    that.canvas = canvas;
                    that.ctx = ctx;
                }                           
                //let site = this.$store.state.storage_global.currentSite;            
                let arr= $help.getLayerMapPosition(Number(that.lidarDatas[0]?.angleOfPitch),this.scanDistance,this.center)            
                var bounds = new AMap.Bounds(arr[0],arr[1])   
                if(!that.canvasLayer){
                    that.canvasLayer = new AMap.CanvasLayer({
                        canvas: that.canvas,
                        bounds: bounds,  
                        opacity:1,  
                        zooms: [2, 30]            
                    });        
                    that.map.add(that.canvasLayer);
                }else{
                    if(that.canvas.width != _width){                    
                        that.canvas.width = _width;
                        that.canvas.height = _width;
                        that.canvasLayer.setCanvas(that.canvas)
                    }
                    that.canvasLayer.setBounds(bounds)                
                }            
                //插值画法                                    
                that.drawHelper(_width/2/this.scanDistance);            
                that.drawTimeText(new Date(that.lidarDatas[that.lidarDatas.length - 1].dataTime.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"))                                 
            }             
        },
        async drawHelper(rp){   
            let yaw =this.$store.state.storage_global.currentSite.yaw;                                 
            let _datas =this.lidarDatas.map(a=>{                
                return {
                    angle:a.straightAngle,
                    distanceData:a.distanceData,
                    data:a.data,  
                    dataTime:a.dataTime,
                }
            });  
            if(_datas.length == 0 || !this.$refs.colorBar)   {
                return;
            }
            console.log(yaw)
            let d = this.scanDistance;   

            var that = this;
            let distanceData =_datas[0].distanceData;            
            let noDataLength =  distanceData[0] /  distanceData[1];//无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let data =new Uint8ClampedArray( d * rp * 2 * d * rp * 2 * 4);
            $help.drawScan(_datas,(obj)=>{
                that.$refs.colorBar.getRgb(obj.value,(color)=>{                 
                    let index = 4*(obj.y * obj.width + obj.x);                 
                    data[index] = Number(color[0])
                    data[index+1] = Number(color[1])
                    data[index+2] = Number(color[2])
                    data[index+3] = Number(color[3]) * 255      
                })                
            },()=>{
                let imageData = new ImageData(data,d * rp * 2,d * rp * 2)
                this.imageData = imageData
                that.ctx.putImageData(imageData,0,0)  
                if(that.canvasLayer){
                    that.canvasLayer.reFresh()
                }      
            },noDataLength,scanLength,_datas[0].distanceData[2],{d:d,rp:rp,yaw:yaw,span:2})                           
        },
        //周期时间显示
        drawTimeText(text){
            if(this.map){
                let pos = this.map.lngLatToContainer(this.center); 
                let angle = this.map.getPitch()
                pos.y = (170/ Math.cos(angle/360)).toFixed(2);            
                let lnglat = this.map.containerToLngLat(pos); 
                let datas = [
                    [lnglat.lng,lnglat.lat,text]
                ]                      
                let opt = {series:[{
                    type:'scatter',
                    data:datas,
                    coordinateSystem:'amap',
                    symbolSize:0,
                    label:{
                        show:true,
                        formatter:'{@[2]}',
                        fontSize:24,                
                    }
                }]}
                if(this.mapEchart && this.mapEchart.setOption){
                    this.mapEchart.setOption(opt) 
                }             
            }
        },  
      }
}
</script>
<style scoped lang="scss">
.map{
    width: 100%;
    height: 100%;
}
</style>